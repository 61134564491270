/**
 * @namespace child.el
 * @since 1.0
 * @desc child.el is where we cache all of our needed DOM elements as jQuery objects.
 */

c.el = {
	doc : $( document ),
	window : $( window )
};

