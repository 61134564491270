/**
 * @namespace child.fn
 * @since 1.0
 * @desc child.fn is were we store our core and shared helper functions. Alphabetical order.
 * Store one offs or larger groups of related functions inside their own module in the modules folder.
 */

c.fn = {

	/**
	 * @function child.fn.execute_ready
	 * @since 1.0
	 * @desc child.fn.execute_ready brings together all functions that must execute on doc ready.
	 */
	execute_ready: function() {
		c.bind_events();
		c.team_directory.init();
	},

	/**
	 * Decode html entities into their respective outputs
	 * Uses for things like the rest api returns on title
	 * to make the special characters outputtable
	 *
	 *
	 * @param {string} s
	 * @returns {string|*}
	 */
	decodeEntities : function( s ){
		var str, temp = document.createElement( 'p' );
		temp.innerHTML = s;
		str = temp.textContent || temp.innerText;
		temp = null;
		return str;
	},

	/**
	 * Wrapper for ReactDOM.render
	 * @param {object} object - created from React.createClass({})
	 * @param {object} data - key value object of data that will become this.props
	 * @param {object} element - JS element ( not the same as $(this) but can be $(this).get(0) )
	 * @returns {*}
	 */
	react_render : function( object, data, element ){
		return ReactDOM.render(
			React.createElement(
				object,
				data
			),
			element
		);
	},

	/**
	 * Sanitize and return html markup that may be used by react
	 *
	 * Set an element like so
	 * <div id="content" dangerouslySetInnerHTML={Life_Blog_React.fn.react_html_markup( $html )}></div>
	 *
	 * @param string $html
	 * @returns {{__html: *}}
	 */
	react_html_markup : function( $html ){
		$html = $html.replace( /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, "" );
		return {__html : $html};

	},



	/**
	 * @function child.fn.execute_resize
	 * @since 1.0
	 * @desc child.fn.execute_resize brings together all functions that must execute on the end of browser resize.
	 */
	execute_resize: function() {
		c.el.doc.trigger( 'de-bounced-resize' );
	},

	
	/**
	 * @function child.fn.execute_scroll
	 * @since 1.0
	 * @desc child.fn.execute_scroll brings together all functions that must execute on the end of browser scroll.
	 */
	execute_scroll: function(){
		c.el.doc.trigger( 'de-bounced-scroll' );
	},



	/**
	 * @function child.fn.execute_load
	 * @since 1.0
	 * @desc child.fn.execute_load brings together all functions that must execute on window load.
	 */
	execute_load: function() {

	},



	/**
	 * Quick debug wrapper for the pre-process
	 * @param data
	 */
	debug : function( data ){
		// @ifdef DEBUG
		console.info( data );
		// @endif
	},

	/**
	 * Show Tooltip
	 *
	 * Opens a tooltip and sets it to this.data.tooltip so it may
	 * be closed by whatever opens this.
	 *
	 * Done this way because we want to be able to click or hover or whatever
	 * from anywhere. That is why there is not close on this.
	 *
	 * @see use c.data.tooltip.remove() to close
	 *
	 * @param el
	 * @param text
	 */
	show_tip : function( el, text ){
		c.fn.debug( 'showing tooltip ... ' );
		if( typeof( c.data.tooltip ) != "undefined" ){
			c.data.tooltip.remove();
		}

		var selector = new Date().getTime();

		var offset = el.offset();
		var width = el.outerWidth();
		var height = el.outerHeight();

		$('footer').after( '<div id="'+selector+'" class="hover-affect-text">' + text + '</div>' );
		var s = c.data.tooltip = $( '#' + selector );
		var centerX = offset.left + width + 30;
		var centerY = offset.top - ( s.outerHeight() / 2 ) - ( height / 2 );

		s.css( {top : centerY, left : centerX, position: 'absolute' } );

	},

	/**
	 * Set the content of the active content mce
	 *
	 * @param string content
	 */
	set_mce_content : function( content ){
		c.fn.debug( content );

		var editor = typeof tinymce !== 'undefined' && tinymce.get('content');
		if( editor ){
			editor.setContent( content );
		}

	},

	/**
	 * Open a media library modal
	 *
	 * This will trigger 'attachment_selected' on whatever element is passed to it ( second param )
	 * This event will receive two arguments ( ev, attachment )
	 *
	 * @param {string} type - type of files which are accepted
	 * @param {jQuery} el - the element which the .on( "attachment_selected" ) is added to
	 * @param {obj} text - { button : %button_text%, title : %library_title% }
	 *
	 */
	library_modal : function( type, el, text ){
		var custom_uploader = wp.media({
			title: text.title,
			button: {
				text: text.button
			},
			library : {
				type : type
			},
			multiple: false
		}).on('select', function() {
			var items = custom_uploader.state().get('selection' );
			var attachment = items.models[0].toJSON();

			c.fn.debug( attachment );

			el.trigger( "attachment_selected", attachment );

		}).open() ;
	},



	/**
	 * @function child.fn.is_json
	 * @since 1.0
	 * @desc Test if a string is valid json.
	 */

	is_json: function( str ) {
		try {
			JSON.parse( str );
		} catch ( e ) {
			return false;
		}
		return true;
	},


	/**
	 * @function child.fn.debounce
	 *
	 * @desc run on only so often during a specified period
	 *
	 * @param func
	 * @param wait
	 * @param immediate
	 * @returns {Function}
	 */
	debounce : function( func, wait, immediate ){
		var timeout;
		return function(){
			var context = this, args = arguments;
			var later = function(){
				timeout = null;
				if( !immediate ){
					func.apply( context, args );
				}
			};
			var callNow = immediate && !timeout;
			clearTimeout( timeout );
			timeout = setTimeout( later, wait );
			if( callNow ){
				func.apply( context, args );
			}
		};
	}
	
};
