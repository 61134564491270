/**
 * @namespace child.bind_events
 * @since 1.0
 * @desc child.bind_events is where all global event handlers are attached
 */

c.bind_events = function(){

	/***
	 * @handler Window Events
	 * @since 1.0
	 * @desc These handlers listen on the window object.
	 */
	c.el.window.on( 'resize', c.fn.debounce( c.fn.execute_resize, 200, false ) ).load( c.fn.execute_load );
	c.el.window.on( 'scroll', c.fn.debounce( c.fn.execute_scroll, 200, false ) );

};