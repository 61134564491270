/**

 * @desc were we can store various state variables, like browser info, viewport height, width eta.
 */

c.state = {
	click : 'click',
	domain : location.protocol + '//' + location.host + '/'

};
