/**
 * @namespace child.data
 * @since 1.0
 * @desc child.data is where we can store/pass data during js operations.
 */

c.data = {};

var $_GET = [];
var parts = window.location.search.substr( 1 ).split( "&" );
for( var i = 0; i < parts.length; i++ ){
	var temp = parts[i].split( "=" );
	$_GET[decodeURIComponent( temp[0] )] = decodeURIComponent( temp[1] );
}
